import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { ORDER_ID } from "../../graphql/query/order";
import {
  Card,
  Col,
  Row,
  Progress,
  Button,
  notification,
  Tooltip,
  Icon,
  message,
  Input,
} from "antd";
import Upload from "../Upload";
import gql from "../../api/gql";
import PrintFilePreview from "../file/PrintFilePreview";
import styled from "styled-components";
import { UPLOAD_ORDER_PRINTFILE } from "../../graphql/mutation/uploadOrderPrintFile";
import _ from "lodash";
import ButtonDownload from "./ButtonDownload";
import { CLOUDFRONT_URL } from "../../config";
const UploadContainer = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 67%;
  .upload-wrapper {
    display: block;
    position: relative;
    height: 0;
    padding-bottom: 67%;
    .upload-area {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
class UploadDesign extends React.Component {
  state = {
    file: this.props.file,
    uploading: false,
    percent: 0,
    disable: this.props.disable
  };

  onUpload = (file) => {
    let query = `mutation createFile($input: NewFile!){
        createFile(input: $input){id}
      }`;
    gql
      .request(query, {
        input: {
          source: "aws",
          key: file.key,
          file_name: file.file.name,
          file_mime: file.file.type,
          file_size: file.file.size,
        },
      })
      .then(({ createFile }) => {
        const { file } = this.state;
        this.setState(
          {
            uploading: false,
            file: { ...file, fileID: createFile.id },
          },
          () => this.props.onChange(this.state.file)
        );
      })
      .catch((err) => {
        this.setState({
          uploading: false,
          percent: 0,
        });
      });
  };

  render() {
    const { file, uploading, percent } = this.state;
    return (
      <UploadContainer>
        <Upload
          disabled={this.props.disable}
          onUpload={this.onUpload}
          onProcess={(e) => {
            this.setState({
              uploading: true,
              percent: Math.round((e.loaded / e.total) * 100),
            });
          }}
          fileList={false}
          accept=".jpg, .png, .tiff, .psd, .dxf"
          className="upload-wrapper"
        >
          <div className="upload-area">
            {uploading ? (
              <Progress
                type="circle"
                percent={percent}
                strokeColor="#1890ff"
                width={50}
              />
            ) : file.fileID ? (
              <PrintFilePreview file_id={file.fileID} />
            ) : (
              <span style={{ textAlign: "center" }}>
                Missing, click to upload or drag to upload
              </span>
            )}
          </div>
        </Upload>
      </UploadContainer>
    );
  }
}
const UploadDesignPersonal = (props) => {
  const { selectedOrder, onClose } = props;
  const [loadingRe, setLoadingRe] = useState(false);
  const [printFiles, setPrintFiles] = useState([]);
  const [newDesignUrls, setNewDesignUrls] = useState([]);
  const [uploadOrderPrintfile, { loading }] = useMutation(
    UPLOAD_ORDER_PRINTFILE
  );
  const [disable, setDisable] = useState(['in_production', 'fulfilled', 'cancelled'].includes(selectedOrder.status))

  const { data } = useQuery(ORDER_ID, {
    fetchPolicy: "network-only",
    variables: { id: selectedOrder.id },
  });
  useEffect(() => {
    if (data) {
      setPrintFiles(data ? data.order.print_files : []);
    }
  }, [data]);

  const reDownloadPrintFile = (id) => {
    const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,6})([\/\w .-]*)*\/?$/;

    let checkDesign = true

    // eslint-disable-next-line no-unused-expressions
    newDesignUrls?.map(el => {
        if (! urlPattern.test(el.url)) {
          checkDesign = false
        } 
    })

    if (! checkDesign) {
      message["error"]("File design is not url.");

      return
    }

    setLoadingRe(true);
    let query = `mutation reDownloadPrintFiles($orderID: String!, $newDesigns: [NewDesignPrint!]!){
      reDownloadPrintFiles(orderID: $orderID, newDesigns: $newDesigns){id,print_files{id
        product_type_print_file_id
        title
        mockup
        fileID
        width
        height}}
    }`;
    gql
      .request(query, {
        orderID: id.toString(),
        newDesigns: newDesignUrls,
      })
      .then((res) => {
        setLoadingRe(false);
        setPrintFiles(res.reDownloadPrintFiles.print_files);
        message["success"](`The print file downloaded successfully`);
        window.location.reload(); 
      })
      .catch((err) => {
        setLoadingRe(false);
        message["error"](_.get(err, "[0].message"));
      });
  };

  const handleChangeDesignUrl = (printTitle, value) => {
    setNewDesignUrls((prev) => {
      const index = prev.findIndex((item) => item.title === printTitle);

      if (index !== -1) {
        // Nếu title đã tồn tại, cập nhật phần tử
        return prev.map((item, i) => (i === index ? { ...item, ...{
          title: printTitle,
          url: value,
        } } : item));
      } else {
        // Nếu title không tồn tại, thêm mới
        return [...prev, {
          title: printTitle,
          url: value,
        }];
      }
    })
  }

  return (
    <div>
      <Row type="flex" gutter={10}>
        {printFiles?.map((print_file, index2) => (
          <Col key={index2} span={6}>
            <Card
              hoverable
              cover={
                <UploadDesign
                  file={print_file}
                  onChange={(file) => {
                    printFiles[index2] = file;
                    setPrintFiles(printFiles);
                  }}
                  disable={disable}
                />
              }
            >
              <Card.Meta
                title={print_file.title}
                description={`${print_file.width} x ${print_file.height}`}
              />
              {print_file.note && (
                <Card.Meta title={null} description={print_file.note} />
              )}

              <div style={{ position: "absolute", bottom: 12, right: 8 }}>
                <ButtonDownload fileId={print_file.fileID} />
              </div>
            </Card>

            {! print_file?.fileID && (
                <>
                  <Tooltip
                    placement="topRight"
                    title="You can enter the design URL to re download"
                  >
                    <Input 
                      style={{marginTop:"15px"}}
                      placeholder="New design URL"
                      onChange={(e) => handleChangeDesignUrl(print_file?.title, e.target.value)}
                    />
                  </Tooltip>
                </>
              )}
          </Col>
        ))}
      </Row>

      {! disable && printFiles?.filter(el => el?.fileID === null).length > 0 && (
        <div
          style={{
            marginTop:"15px"
          }}
        >
          <Tooltip
            placement="topRight"
            title="Re download designs"
          >
            {setLoadingRe}
            <Button
              type="primary"
              loading={loadingRe}
              onClick={() => {
                reDownloadPrintFile(selectedOrder.id);
              }}
            >
              Re download designs
            </Button>
          </Tooltip>
        </div>
      )}
      
      {selectedOrder.variant_image ? (
        <div>
          <h4 style={{ marginTop: 50 }}>Image Mockup</h4>
          <img
            alt=""
            src={
              selectedOrder.variant_image ? selectedOrder.variant_image : null
            }
            style={{ width: "50%" }}
          />
        </div>
      ) : null}

      {selectedOrder.product.images && selectedOrder.product.images.length > 0 && (
        <div>
          <h4 style={{ marginTop: 50 }}>Product Mockup</h4>
          <img
            alt=""
            // src={
            //   selectedOrder.product.images
            //     ? selectedOrder.product.images[0]
            //     : null
            // }
            src={
              selectedOrder.product.images
                ? selectedOrder.product.images[0].indexOf("http") === 0
                  ? selectedOrder.product.images[0]
                  : `${CLOUDFRONT_URL}/${selectedOrder.product.images[0]}`
                : null
            }
            style={{ width: "50%" }}
          />
        </div>
      )}
      <div
        style={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          borderTop: "1px solid #e8e8e8",
          padding: "10px 16px",
          textAlign: "right",
          left: 0,
          background: "#fff",
          borderRadius: "0 0 4px 4px",
        }}
      >
        <Button
          disabled={disable}
          type="primary"
          loading={loading}
          onClick={() => {
            uploadOrderPrintfile({
              variables: {
                order_id: selectedOrder.id,
                is_ready: true,
                print_files: printFiles.map((item) => {
                  return { id: item.id, fileID: item.fileID };
                }),
              },
            })
              .then((res) => {
                notification.success({
                  message: "Upload order printfile success",
                });
                onClose();
              })
              .catch((err) => {
                notification["error"]({
                  message: _.get(err, "[0].message"),
                });
              });
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default UploadDesignPersonal;
